.App {
  text-align: center;
  font-family: "Inter";
}

.singleNoDataImage {
  /* border: 1px solid red; */
  width: 100%;
  height: 100%;
}


/* material-form-control-container important */
/* full-height-text-box  important */