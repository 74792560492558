.flex-flex-start-flex-start,
.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.flex-flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-space-between,
.flex-space-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-flex-start-flex-end {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.flex-space-between-flex-end {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.flex-felx-end-center,
.flex-flex-end-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex {
  display: flex;
  align-items: center;
}
.flex-space-between-flex-start,
.flex-space-between-start {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.mt-0px {
  margin-top: 0px !important;
}
.mt-4px {
  margin-top: 4px;
}
.p-16px {
  padding: 16px;
}
.pt-20px {
  padding-top: 20px;
}

.pt-10px {
  padding-top: 10px;
}

.pl-10px {
  padding-left: 10px;
}

.pl-20px {
  padding-left: 20px;
}
.border-red {
  border: 1px solid red !important;
}
.border-blue {
  border: 1px solid #00f;
}
.border-green {
  border: 1px solid green;
}
.position-relative {
  position: relative;
}
.width-100 {
  width: 100% !important;
}

.width-42px {
  width: 42px;
}

.width-97 {
  width: 97%;
}

.height-42px {
  height: 42px;
}
.height-100 {
  height: 100% !important;
}
.width-50-10px {
  width: calc(50% - 10px);
}
.width-50 {
  width: 50% !important;
}
.height-50 {
  height: 50% !important;
}
.mr-8px {
  margin-right: 8px;
}
.text-left {
  text-align: left;
}
.mt-16px {
  margin-top: 16px;
}
.mt-20px {
  margin-top: 20px;
}
.ml-2px {
  margin-left: 2px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-3 {
  margin-left: 3px;
}
.mb-12px {
  margin-bottom: 12px;
}
.mb-8,
.mb-8px {
  margin-bottom: 8px;
}

.mb-3,
.mb-3px {
  margin-bottom: 3px;
}
.mb-5px {
  margin-bottom: 5px;
}
.mb-30px {
  margin-bottom: 30px;
}
.mt-5px {
  margin-top: 5px;
}
.mb-10,
.mb-10px {
  margin-bottom: 10px;
}
.mr-20px {
  margin-right: 20px;
}

.pt-5px {
  padding-top: 5px;
}
.pt-3px {
  padding-top: 3px;
}
.mb-16px {
  margin-bottom: 16px !important;
}
.mt-30px {
  margin-top: 30px;
}
.mb-20px {
  margin-bottom: 20px;
}
.mb-0 {
  margin-bottom: 0;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}
.hide-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.p-10px {
  padding: 10px;
}
.height-700px {
  height: 700px;
}
.height-20px {
  height: 20px;
}
.overflow-scroll {
  overflow: scroll;
}
.width-400px {
  width: 400px;
}
.width-150px {
  width: 150px;
}
.bold {
  font-weight: 900;
}
p {
  margin: 0;
  margin-bottom: 0 !important;
}
.mr-5px {
  margin-right: 5px;
}
.ml-5px {
  margin-left: 5px;
}
.width-200px {
  width: 200px;
}
.p-20px {
  padding: 20px;
}
.ml-4px {
  margin-left: 4px;
}
.ml-10px {
  margin-left: 10px;
}
.ml-15px {
  margin-left: 15px;
}
.ml-16px {
  margin-left: 16px;
}
.ml-20px {
  margin-left: 20px;
}
.mt-10px {
  margin-top: 10px;
}
.m-0px {
  margin: 0;
}
.m-16px {
  margin: 16px;
}
.width-100px {
  width: 100px;
}
.mr-10px {
  margin-right: 10px;
}
.ml-0px {
  margin-left: 0 !important;
}
.mt-24px {
  margin-top: 24px;
}
.mb-24px {
  margin-bottom: 24px;
}
.mr-16px {
  margin-right: 16px;
}
.mt-8px {
  margin-top: 8px;
}
.ml-8px {
  margin-left: 8px;
}
.ml-24px {
  margin-left: 24px;
}
input[type="checkbox"],
input[type="radio"] {
  accent-color: #8369fc;
}
.flex-reverse {
  display: flex;
  flex-direction: row-reverse;
}
.flex-flex-start-flex-start-column {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.flex-justify-center-align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cursor-p {
  cursor: pointer;
}

input[type="checkbox"] {
  margin: 0 0.5rem;
}
